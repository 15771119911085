import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Text } from '@buffer-mono/legacy-bufferapp-components'

const Name = styled.span`
  color: #343e47;
`

// @ts-expect-error TS(7031) FIXME: Binding element 'name' implicitly has an 'any' typ... Remove this comment to see the full error message
const NameComponent = ({ name, small }) => (
  <Text size={small ? 'small' : undefined} weight="bold">
    <Name>{name}</Name>
  </Text>
)

NameComponent.defaultProps = {
  small: false,
}

NameComponent.propTypes = {
  name: PropTypes.string.isRequired,
  small: PropTypes.bool,
}

export default NameComponent
