import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import { Button, Text } from '@buffer-mono/legacy-bufferapp-components'

import { blue, grayLight } from '@bufferapp/ui/style/colors'

const Header = styled.header`
  margin: 0 0 0.5rem;
  height: auto;
`

const List = styled.ul`
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`

const Item = styled.li`
  width: 48%;
  list-style: none;
  cursor: pointer;
  display: inline-block;
  box-sizing: border-box;
  padding: 0;
  text-decoration: none;
  text-shadow: none;
  border: 1px solid ${grayLight};
  border-radius: 3px;
  text-align: center;
  user-select: none;
  white-space: nowrap;
  margin: 2px;

  &:last-of-type {
    width: 100%;
  }

  > button {
    padding: 0.5rem 0.25rem !important;
  }

  ${(props) =>
    // @ts-expect-error TS(2339) FIXME: Property 'inactive' does not exist on type 'Themed... Remove this comment to see the full error message
    props.inactive &&
    css`
      opacity: 1;
    `}

  ${(props) =>
    // @ts-expect-error TS(2339) FIXME: Property 'active' does not exist on type 'ThemedSt... Remove this comment to see the full error message
    props.active &&
    css`
      background: ${blue};
      border-color: ${blue};
    `}

  ${(props) =>
    // @ts-expect-error TS(2339) FIXME: Property 'disabled' does not exist on type 'Themed... Remove this comment to see the full error message
    props.disabled &&
    css`
      color: #aaaaaa !important;
      opacity: 0.3 !important;
      background: transparent !important;
      cursor: not-allowed;
    `}
`

// @ts-expect-error TS(7031) FIXME: Binding element 'presets' implicitly has an 'any' ... Remove this comment to see the full error message
const Presets = ({ presets, selectPreset, startDate, endDate }) => {
  // @ts-expect-error TS(7006) FIXME: Parameter 'preset' implicitly has an 'any' type.
  const presetButtons = presets.map((preset, index) => {
    const dataTip = preset.disabled
      ? "We don't have complete data for this range."
      : null
    const handleClick = preset.disabled ? null : () => selectPreset(preset)
    const buttonTextColor = preset.selected ? 'white' : undefined

    return (
      <Item
        // @ts-expect-error TS(2769) FIXME: No overload matches this call.
        active={preset.selected}
        inactive={!preset.selected}
        disabled={preset.disabled}
        key={preset.name.toLowerCase().replace(' ', '-')}
        data-tip={dataTip}
      >
        {/* @ts-expect-error */}
        <Button noStyle fillContainer onClick={handleClick}>
          <Text size="small" color={buttonTextColor}>
            {preset.name}
          </Text>
        </Button>
      </Item>
    )
  })

  return (
    <Header>
      <List>{presetButtons}</List>
    </Header>
  )
}

Presets.defaultProps = {
  startDate: null,
  endDate: null,
}

Presets.propTypes = {
  selectPreset: PropTypes.func.isRequired,
  startDate: PropTypes.number,
  endDate: PropTypes.number,
}

export default Presets
